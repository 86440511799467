@import-normalize;

html {
   display: flex;
   min-height: 100%;
}

body {
   display: flex;
   flex-grow: 1;
   margin: 0;
   font-family: 'Montserrat', sans-serif;
}

#root {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
}

