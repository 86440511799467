/*Clara este lo saqué de este video de youtube. Hay que pasarlo al formato que utilizamos y poniendo etiquetas similares a las nuestras https://www.youtube.com/watch?v=xUwkGVin9-8 */


/* .board {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    max-width: 600px;
    margin: 100px auto;
} */

.memo-block {
    cursor: pointer;
    aspect-ratio: 1 / 1;
}

.memo-block-front, .memo-block-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.memo-block-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.memo-block-flipped {
    transform: rotateY(180deg);
}

.memo-block-back {
    background-color: rgba(250, 250, 250, 1);
    transform: rotateY(180deg);
    box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
}

.memo-block-front {
    background-image: url("../../../image/logoverde.png");
    background-color: #45B2B6;
}

